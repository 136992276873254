.ubf-address {
	font-style: normal;

	a:not(.ubf-button) {
		@include ubf-link();
		font-weight: normal;
	}

	&__detail {
		& + & {
			margin-top: $base;
		}
	}


	&__details {
		* + & {
			margin-top: $base;
		}
	}


	&__detailName {
		display: inline-block;
		margin: 0;

		&::after {
			content: ':';
		}
	}


	&__detailValue {
		display: inline-block;
		margin: 0;
	}


	&__image {
		@include aspectRatio(1);
		position: relative;
	}




	&__name {
		@include ubf-font(h);


		.ubf-address__title + & {
			margin-top: $base;
		}
	}


	&__picture {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	&__picture--cropped {
		width: 100%;
		height: auto;
	}

	&__role {
		@include ubf-font(base);

		.ubf-address__title + & {
			margin-top: $base;
		}
	}


	&__title {
		@include ubf-font(h3);
	}


	// &__text {
	// 	@include ubf-font(base);

	// 	.ubf-preview__title + & {
	// 		margin-top: $base / 2;

	// 		@include media($largeLayoutBreakpoint) {
	// 			margin-top: $base;
	// 		}
	// 	}
	// }


	// &__textBody {
	// 	@include visuallyHidden();

	// 	@include media($largeLayoutBreakpoint) {
	// 		@include visuallyHidden(false);
	// 	}
	// }


	// &__picture {
	// 	@include aspectRatio(1);
	// 	width: 100%;
	// }


	// &__thumbLink {
	// 	display: block;
	// }


	// &__title {
	// 	@include ubf-font(h3);
	// }
}
