.ubf-accordionController {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	line-height: 0;

	@include media($largeLayoutBreakpoint) {
		align-items: flex-end;
		flex-direction: column;
		line-height: inherit;
	}


	&__action {
		@include ubf-font(titleAction);
		@include ubf-link();

		&:not(:first-child) {
			margin-left: $base;

			@include media($largeLayoutBreakpoint) {
				margin-left: 0;
			}
		}

		&[disabled] {
			opacity: $opacityInputDisabled;
			text-decoration: none;
		}

		@include media($largeLayoutBreakpoint) {
			display: block;
			padding: $baseHalf 0;
			text-align: right;
		}
	}
}
