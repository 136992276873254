.ubf-facets {
  display: flex;
  flex-direction: column;

  &__form {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding-bottom: 20px;
	@include media($largeLayoutBreakpoint) {
	  flex-direction: column;
	}
  }

  &__form-label {
	color: $colorInput;
  }

  &__form-search {
  }

  &__form-facets {
	background-color: $colorBgSubMenu;
	padding: $containerPaddingSmall;
  }

  &__title {
	@include ubf-font(base);
	font-weight: bold;
	cursor: pointer;
	padding-left: 10px;

	& > * {
	  pointer-events: none;
	}
  }

  &__facets {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	border-top: 1px solid $colorInput;
	padding: 7px 0 0 0;
	justify-content: space-between;
  }

  &__facet {
	position: relative;
	flex-grow: 1;
	width: 100%;
	@include media($largeLayoutBreakpoint) {
	  width: 20%;
	}

	&.js-active {
	  background-color: $colorBgSubMenu;

	  .ubf-facets__values {
		opacity: 1;
		background-color: $colorBgSubMenu;
		padding: 5px 5px 2px 5px;
	  }

	  .ubf-facets__link {
		max-height: 50px;
		opacity: 1;
		margin-bottom: 4px;
		padding: 4px 7px;
		pointer-events: all;
	  }

	  .ubf-facets__icon {
		transform: rotate(90deg);
	  }
	}

  }

  &__icon {
	display: inline-block;
	line-height: 0;
	height: 0.75rem;
	width: 0.75rem;
	position: relative;
	text-align: center;
	transition: transform 0.25s ease-out;
	transform: rotate(0deg);

	[data-type="svg"] {
	  height: auto;
	  width: 0.75rem;
	}
  }

  &__values {
	position: relative;
	top: 0;
	@include media($largeLayoutBreakpoint) {
	  position: absolute;
	  top: 35px;
	}
	flex-direction: column;
	z-index: 1;
	opacity: 1;
	transition: all 0.25s ease-out;
	background-color: transparent;
	padding: 4px 0 4px 0;
	width: 100%;
  }

  &__value {
	margin: 0;
	padding: 0;
  }

  &__link {
	display: flex;
	justify-content: space-between;
	max-height: 0;
	opacity: 0;
	// display: inline-block;
	transition: all 0.25s ease-out;
	@include ubf-font(facetValue);
	text-decoration: none;
	color: $colorBase;
	background-color: $colorBgSubMenu;
	padding: 0 7px;
	margin-bottom: 0;
	pointer-events: none;

	&.js-selected {
	  @include ubf-highlightColor(background-color);
	}

	.js-active &.js-disabled {
	  opacity: 0.3;
	  pointer-events: none;
	}
	& > * {
	  pointer-events: none;
	}
  }
  &__link-count {
	color: $colorHighlightBaseText;
	padding: 0 2px;
  }
  &__selected-list {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	gap: 10px;
	padding-bottom: $base4;
  }

  &__selected-link {
	@include ubf-font(facetValue);
	@include ubf-highlightColor(background-color);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 5px;
	text-decoration: none;
	color: $colorBase;
	padding: 4px 7px;
	margin-bottom: 0;
	svg {
	  padding-top: 2px
	}

  }

  &__search-button {
	margin-bottom: $base2;
	display: flex;
	justify-content: flex-end;
  }
  .js-result-hidden &__results-table {
	display: none !important;
  }
  [data-ubf-show-search-button="true"] &__selected-list {
	padding-bottom: $base1;
  }
  [data-ubf-show-search-link] &__selected-list {
	padding-bottom: $base1;
  }

	&__targets {
		display: block;
	}

	&__target-title {
		display: inline-block;
		@include ubf-outlineOnFocus();
		@include ease(background-color border-color, $duration4);
		border-color: transparent;
		border-style: solid;
		border-width: $boxBorderSize 0 0 0 ;
		background-color: $colorBgTabs;
		color: $colorTabs;
		padding: $baseHalf $base;
		margin: 0;
		cursor: pointer;
		&.js-active {
			@include ubf-highlightColor(border-color);
			font-weight: bold;
		}
	}
	&__target-options {
		display: block;
		padding-top: $base;
		background-color: $colorBgTabs;
	}

}
