.ubf-excerpt {
	&__text {
		@include ubf-font(base);

		.ubf-highlights__excerpts & {
			@include ubf-invertedColorFaculties();
		}

		* + & {
			margin-top: $base * 0.5;

			@include media($largeLayoutBreakpoint) {
				margin-top: $base;
			}

			.ubf-sidebar & {
				margin-top: 0;

				@include media($largeLayoutBreakpoint) {
					margin-top: 0;
				}
			}
		}
	}



	&__title {
		@include ubf-font(h3);

		.ubf-highlights__excerpts & {
			@include ubf-invertedColorFaculties();
		}

		.ubf-sidebar & {
			@include media($largeLayoutBreakpoint) {
				@include ubf-font(h);
			}
		}
	}
}
