.ubf-news {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: repeat(auto-fill, 1fr);
	grid-auto-rows: auto;

	gap: $gridGutterLarge $gridGutterLarge;

	grid-template-areas:
		"news--1"
		"news--2"
		"news--3"
		"news--4"
		"news--5"
		"news--6";
	@include media($largeLayoutBreakpoint) {
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: repeat(auto-fill, 1fr);
		grid-template-areas:
		"news--1 news--1 news--3"
		"news--2 news--2 news--3"
		"news--4 news--5 news--5"
		"news--4 news--6 news--6";
	}


	&__item {
		@include ubf-highlightColor(border-color);
		border-width: $boxBorderSize;
		border-style: solid;
		// assing template areas
		@for $i from 1 through 6 {
			&:nth-child(#{$i}) {
				grid-area: news--#{$i};
			}
		}


		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr;
		gap: $gridGutterLarge $gridGutterLarge;
		@include media($largeLayoutBreakpoint) {
			&:nth-child(3),
			&:nth-child(4) {
			//flex-direction: column;
				grid-template-columns: 1fr;
				grid-template-rows: 1fr 1fr;
				grid-auto-flow: column
			}
		}
		text-decoration: none;
		color: $colorBase;

		@include ease(background-color color, $duration2);
		&:hover {
			@include ubf-highlightColor(background-color);
		}
	}
	&__text {
		flex-basis: 100%;
		flex-grow: 1;
		padding: $gridGutterSmall;
	}
	&__image {
		flex-grow: 1;
		flex-basis: 100%;
		padding-top: 100%;
		position: relative;
	}
	&__picture {
		position: absolute;
		top:0;
		left:0;
		bottom:0;
		right:0;
		flex-wrap: nowrap!important;
		.ubf-picture__img {
			@include objectFit(cover);
			width:100%;
		}
	}
	&__info {
		@include ubf-font(base);
		padding-bottom: $base2;
	}
	&__title {
		@include ubf-font(h4);

		@include media($largeLayoutBreakpoint) {
			@include ubf-font(h3);
		}
	}
	&__moreLink {
		@include ubf-link();
	}
}
