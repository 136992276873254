.ubf-event {
	&__content {
		.ubf-event__title + & {
			margin-top: $base;

			@include media($largeLayoutBreakpoint) {
				margin-top: $base2;
			}

			.ubf-sidebar & {
				@include media($largeLayoutBreakpoint) {
					margin-top: $base;
				}
			}
		}
	}


	&__title {
		@include ubf-font(h3);

		.ubf-sidebar & {
			@include media($largeLayoutBreakpoint) {
				@include ubf-font(h);
			}
		}
	}


	&__titleLink {
		@include ubf-link();
		text-decoration: none;
	}
}
