.ubf-alert {
	@include fadeOut($duration4);
	display: none;
	min-height: 100vh;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
	justify-content: center;
	align-items: flex-start;

	@include media($largeLayoutBreakpoint) {
		align-items: center;
	}

	&.ubf-js-fixed {
		position: fixed;
	}

	&.ubf-js-beforeActive {
		display: flex;
		will-change: opacity, visibility;
	}

	&.ubf-js-duringActive {
		@include fadeIn();
	}


	&::before {
		background-color: $colorBgAlert;
		bottom: 0;
		content: '';
		left: 0;
		position: fixed;
		right: 0;
		top: 0;
		width: 100%;
		z-index: -1;
	}


	&__container {
		background-color: $colorBgBase;
		border: $boxBorderSize solid $colorHighlightBase;
		min-height: 100%;
		padding: $base $base4 $base $base;
		width: 100%;

		@include media($largeLayoutBreakpoint) {
			min-height: 0;
			max-width: $alertMaxWidth;
			padding: $base2 $base4 $base2 $base2;
			position: relative;
		}

		.ubf-alert.ubf-js-errored & {
			border: $boxBorderSize solid $colorAlertBorder;
		}
	}


	&__toggler {
		height: $base4;
		padding: $base;
		position: fixed;
		right: 0;
		top: 0;
		width: $base4;
		z-index: 3;

		@include media($largeLayoutBreakpoint) {
			position: absolute;
		}
	}


	&__togglerIcon {
		display: block;
		height: 100%;
		width: 100%;


		[data-type="svg"] {
			width: 100%;
			height: 100%;
		}
	}


	&__togglerLabel {
		@include visuallyHidden();
	}
}
