.ubf-icons {
	align-items: center;
	display: inline-flex;
	flex-direction: row;
	float: right;
	padding-top: 1px;

	&__icon,
	&__link {
		& + & {
			margin-left: $base;
		}
	}
}
