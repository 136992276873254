.ubf-mainnews {
	margin-bottom:$base2;

	@include dynamicColumns($hSpace: $gridGutterSmall, $vSpace: $gridGutterLarge, $hSpacePos: right);


	@include media($largeLayoutBreakpoint) {
		@include dynamicColumns($hSpace: $gridGutterLarge, $vSpace: $gridGutterLarge, $hSpacePos: right);
	}

	&__mainnews-item {
		width: dynamicColumnSize(1, 1, $gridGutterSmall);

		@include media($largeLayoutBreakpoint) {
			width: dynamicColumnSize(1, 3, $gridGutterLarge);
		}

	}
	&__moreLink {
		@include ubf-link();
	}
}
