.ubf-previewsSliderNavigation {

  	position: absolute;
  	width: 100%;

  	top: calc(33% - #{$base4} * 0.5);

	.ubf-contentBlock--previewsExtended & {
	  top: calc(25% - #{$base4} * 0.5);
	}

	&__icon {
		@include ease(background-color, $duration2);
		@include ubf-highlightColor(color);
		display: inline-block;
		height: $galleryNavigationIconSize;
		position: relative;
		width: $galleryNavigationIconSize;
		pointer-events: none;

		.ubf-body:not(.ubf-body--faculty) & {
			color: $colorBase;
		}

		.ubf-previewsSliderNavigation__item--prev & {
			transform: rotate(180deg);
		}

		.ubf-previewsSliderNavigation__item--next & {
		}

		.ubf-previewsSliderNavigation__link.ubf-js-current & {
			background-color: currentColor;
		}


		[data-type="svg"] {
			height: 100%;
			width: auto;
		}
	}

	&__item {
		line-height: 0;
		pointer-events: none;
	  	width: $base4;
		height: $base4;
	  	box-sizing: content-box;
		margin: 0 - $base1;

		border: 2px solid $colorBgBase;
		@include ease(border-color, $duration2);
		  @include ease(transform, $duration2);

		&:hover {
			border-color: $colorBase;
		  }

		  &--prev,
		  &--next {
			  html.no-js & {
				  display: none;
			  }
		}

	}

	&__items {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		pointer-events: none;
	}


	&__link {
		align-items: center;
		display: flex;
		justify-content: center;
		min-height: $base4;
		pointer-events: all;
		width: 100%;
	  	@include ubf-highlightColor(background-color);

		.ubf-previewsSliderNavigation__item--next & {
			justify-content: center;
		}

		&:not(:hover):focus {
			//outline: $focusOutlineStyle $colorInverted 1px;
			//outline-offset: 1px;
			outline: none;
		}
	  	&.ubf-js-disabled {
		  	pointer-events: none;
			background-color: $colorBgSubMenu;
			opacity: 0;
		}

	}
	.ubf-contentBlock--videoreel & .ubf-js-disabled  {
		display: none;
	}
	.ubf-previewsSliderNavigation__item:has(.ubf-js-disabled) {
		border: 2px solid transparent;
	}
}

.ubf-contentBlock--previews {

	@keyframes play {
		0% {
			background-color: rgba(20, 245, 180, 1);
		}
		70% {
			background-color: rgba(20, 245, 180, 1);
		}
		100% {
			background-color: rgba(20, 245, 180, 0);
		}
	}
	.js-inView {
		animation: play 2s;
		animation-iteration-count: 1;
		animation-fill-mode: forwards;
	}

  .ubf-previewsSliderNavigation__item--next,
  .ubf-previewsSliderNavigation__item--prev {
	z-index: 10;
	transform: translateX(22px);
	  border: none;
	@include media($smallLayoutOnly) {
	  overflow: hidden;
		transform: translateX(0px);
		// @include ubf-highlightColor(background-color, 0.4);
		width: $base4;
	}
	.ubf-previewsSliderNavigation__link {
	  transition: background-color 0.4s, border-color 0.4s, opacity 0.4s;
	  background-color: rgba(255, 255, 255, 0);
		border-color: rgba(255, 255, 255, 0);;

	}
  }
  .ubf-previewsSliderNavigation__item--prev {
	  transform: translateX(-22px);
	@include media($smallLayoutOnly) {
	  transform: translateX(0px);
	}
  }
}

.ubf-contentBlock--previews:hover {
	.ubf-previewsSliderNavigation__item--next,
	.ubf-previewsSliderNavigation__item--prev {
		transform: translateX(0);
		@include media($smallLayoutOnly) {
			opacity: 0.6;
			width: $base4;
			border-width: 2px;
		}

		.ubf-previewsSliderNavigation__link {
			@include ubf-highlightColor(background-color, 1);
			border: 2px solid rgba(255, 255, 255, 1);

			&.js-inView {
				background-color: rgba(20, 245, 180, 1) !important;
			}

			&.ubf-js-disabled {
				background-color: $colorBgSubMenu !important;
				opacity: 0;
			}
		}
	}
}


