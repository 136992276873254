.ubf-modal {
	// @include storeValuesForJs((enableScrollMonitor: false))
	@include fadeOut($duration4);
	background-color: $colorBgModal;
	display: none;
	height: 100vh;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	width: 100%;

	&.ubf-js-fixed {
		position: fixed;
	}


	&.ubf-js-beforeActive {
		@include media($largeLayoutBreakpoint) {
			display: block;
			will-change: opacity, visibility;
		}
	}

	&.ubf-js-duringActive {
		@include media($largeLayoutBreakpoint) {
			@include fadeIn();
		}
	}


	&__content {
		@include media($largeLayoutBreakpoint) {
			@include ubf-container();
			height: 100%;
			position: relative;
		}
	}


	&__item {
		height: 100%;
		position: relative;
		width: 100%;
	}


	&__toggler {
		height: $menuTogglerHeight;
		position: absolute;
		right: $containerPaddingLarge - $base4;
		top: $base4;
		width: $menuTogglerWidth;
		z-index: 200;
	}
}
