.ubf-field {
	@include ubf-font(base);

	&--disabled {
		opacity: $opacityInputDisabled;
	}

	&__button {
		.ubf-field--search .ubf-field__input--text + & {
			margin-left: $base2;
		}


		.ubf-field__fields--grid & {
			width: auto;

			@include media($largeLayoutBreakpoint) {
				align-self: flex-end;
				min-width: initial;
				width: dynamicColumnSize(1, 3, $gridGutterLarge);
			}
		}

		&--file {
			margin-top: -$base4;

			@include media($largeLayoutBreakpoint) {
				position: absolute;
				// left: staticColumnSize(4, 9, $gridGutterLarge);
				left: $base * 30;
				margin-top: 0;
				margin-left: $base2;
				top: 0.65rem;
			}


			.ubf-field__input--file:focus + & {
				outline: 1px solid $colorBase;
			}
		}
	}

	&__content {
		margin-left: -$base3;

		.ubf-field--group > & {
			margin-left: 0;
		}
	}


	&__description {
		margin-left: $base3;

		&:not(&--extended) {
			@include ubf-font(fieldDescription);
			margin-top: $baseHalf;
		}

		.ubf-field--password &,
		.ubf-field--select &,
		.ubf-field--text &,
		.ubf-field--textarea & {
			margin-top: $baseHalf;
		}

		.ubf-field--checkbox &,
		.ubf-field--radio & {
			margin-left: $base6;
		}
	}


	&__errors {
		@include ubf-font(fieldError);
		color: $colorError;
		margin-left: $base3;
		margin-top: $baseHalf;
	}


	&__field {
		width: dynamicColumnSize(1, 1, $gridGutterLarge);

		.ubf-field__fields--horizontal > & {
			@include media($largeLayoutBreakpoint) {
				display: inline-block;
				width: auto;
				margin-left: $base3;
				margin-top: 0;
			}
		}

		.ubf-field__fields--grid > & {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(1, 3, $gridGutterLarge);
			}

			&--double {
				@include media($largeLayoutBreakpoint) {
					width: dynamicColumnSize(2, 3, $gridGutterLarge);
				}
			}
		}

		.ubf-field--checkboxes &,
		.ubf-field--radios & {
			margin-top: 0;
		}
	}



	&__fields {
		@include dynamicColumns($hSpace: $gridGutterLarge, $vSpace: $base3, $hSpacePos: left, $vSpacePos: top);//, $childrenSelector: '.ubf-form__field');
		position: relative;

		.ubf-field--checkboxes &,
		.ubf-field--radios & {
			margin-top: 0;
		}

		&--horizontal {
			@include media($largeLayoutBreakpoint) {
				font-size: 0;
				display: block;
				margin-top: 0;
				margin-left: -$base3;
			}
		}

		// &--grid {
		// 	@include media($largeLayoutBreakpoint) {
		// 		font-size: 0;
		// 		display: block;
		// 		margin-top: 0;
		// 		margin-left: -$base3;
		// 	}
		// }
	}


	&__info {
		color: $colorInput;
		display: block;

		&--strong {
			color: $colorBase;
		}

		.ubf-field--radio &,
		.ubf-field--checkbox & {
			margin-left: $base3;
		}

		.ubf-field__label + & {
			margin-top: -$base;

			@include media($largeLayoutBreakpoint) {
				margin-top: 0;
			}
		}

		.ubf-field__inputWrapper--extended & {
			@include media($largeLayoutBreakpoint) {
				padding: $inputPadding 0;
				margin-left: 0;
				width: dynamicColumnSize(1, 6, $gridGutterSmall);
			}

			&--compact {
				@include media($largeLayoutBreakpoint) {
					width: dynamicColumnSize(2, 12, $gridGutterSmall);
				}
			}

			&--right {
				@include media($largeLayoutBreakpoint) {
					text-align: right;
				}
			}

			&--first {
				color: $colorBase;
				margin-left: $base3;
				flex-grow: 1;
			}
		}
	}


	&__input {
		@include ubf-font(base);
		background-color: $colorBgInput;
		color: $colorInput;

		&:focus {
			@include ubf-highlightColor(outline-color);
			outline-style: solid;
			outline-width: $inputBorderSize;
		}

		&--file {
			height: $base4;
			margin-bottom: $base2;
			opacity: 0;
			padding-left: $base2;
			padding-right: $base2;
			width: $base * 16 + $base4;

			@include media($largeLayoutBreakpoint) {
				margin-bottom: 0;
			}
		}

		&--password,
		&--text {
			background-color: $colorBgBase;
			border: $inputBorderSize solid $colorInputBorder;
			padding: $inputPadding;
			width: 100%;

			.ubf-form--login & {
				background-color: transparent;
			}

			.ubf-field--invalid & {
				border-color: $colorError;
			}

			.ubf-form__field--full & {
				@include media($largeLayoutBreakpoint) {
					max-width: staticColumnSize(6, 8, $base2);
				}

				.ubf-contentBlock--formSmall & {
					@include media($largeLayoutBreakpoint) {
						max-width: none;
					}
				}
			}

			.ubf-field__fields & {
				@include media($largeLayoutBreakpoint) {
					max-width: none;
				}
			}

			.ubf-field__label--radioText & {
				padding: $inputNestedPadding $inputPadding;
			}

			.ubf-field--search & {
				width: auto;
				flex-grow: 1;
			}
		}

		&--textarea {
			background-color: $colorBgBase;
			border: $inputBorderSize solid $colorInputBorder;
			min-height: $base2 + getFontSize(base) * getLineHeight(base) * 4;
			padding: $base;
			width: 100%;

			.ubf-form__field--full & {
				@include media($largeLayoutBreakpoint) {
					max-width: staticColumnSize(6, 8, $base2);
				}

				.ubf-contentBlock--formSmall & {
					@include media($largeLayoutBreakpoint) {
						max-width: none;
					}
				}
			}

			.ubf-field--invalid & {
				border-color: $colorError;
			}
		}

		&--select {
			background-color: transparent;
			border: $inputBorderSize solid $colorInputBorder;
			// needed because on OSX to reset the appearance is not enough
			border-radius: 0;
			min-width: $buttonMinWidth;
			padding: $inputPadding $base2 $inputPadding $inputPadding;
			position: relative;
			width: 100%;
			z-index: 2;
			appearance: none;

			&::-ms-expand {
				display: none;
			}

			.ubf-field--invalid & {
				border-color: $colorError;
			}
		}

		&--checkbox,
		&--radio {
			@include visuallyHidden();
		}
	}


	&__inputWrapper {
		margin-left: $base3;
		position: relative;

		.ubf-field--select & {
			background-color: $colorBgBase;
		}

		&--extended {
			@include media($largeLayoutBreakpoint) {
				@include dynamicColumns($hSpace: $gridGutterSmall, $hSpacePos: right);
			}
		}
		&--right {
			text-align: right;
			.ubf-field__label {
				display: inline-block;
			}
		}
		.ubf-field--horizontal & {
			@include media($largeLayoutBreakpoint) {
				display: inline-block;
			}
		}

		.ubf-form__field--full.ubf-field--select & {
			@include media($largeLayoutBreakpoint) {
				max-width: staticColumnSize(6, 8, $base2, -$base2);
			}

			.ubf-contentBlock--formSmall & {
				@include media($largeLayoutBreakpoint) {
					max-width: staticColumnSize(6, 6, $base2, -$base2);
				}
			}
		}

		.ubf-field--search & {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;

			@include media($largeLayoutBreakpoint) {
				max-width: staticColumnSize(6, 8, $base2, -$base2);
			}
		}

		.ubf-field--radioText & {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: flex-start;
			position: relative;
		}

		.ubf-field--file & {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}
	}


	&__label {
		@mixin replaceInput($circle: true) {
			&::before {
				border: $inputBorderSize solid $colorInputBorder;
				@if $circle {
					border-radius: 50%;
				}
				content: '';
				height: $radioOuterSize;
				left: -$base3;
				position: absolute;
				top: $base;
				vertical-align: middle;
				width: $radioOuterSize;
			}

			&::after {
				@include ease(opacity, $duration2);
				@include ubf-highlightColor(background-color);
				@if $circle {
					border-radius: 50%;
				}
				content: '';
				height: $radioInnerSize;
				left: -$base3 + ($radioOuterSize - $radioInnerSize) * 0.5;
				opacity: 0;
				position: absolute;
				top: $base + ($radioOuterSize - $radioInnerSize) * 0.5;
				vertical-align: middle;
				width: $radioInnerSize;
			}

			input:focus + & {
				@include ubf-highlightColor(outline-color);
				outline-width: $inputBorderSize;
				outline-style: solid;
			}
		}

		@include ubf-font(base);
		border-color: transparent;
		border-style: solid;
		border-width: $inputBorderSize 0;
		color: $colorInput;
		display: block;
		margin-left: $base3;
		padding: $inputPadding 0;
		position: relative;

		&--strong {
			color: $colorBase;
		}

		&--important {
			@include ubf-font(h3);
			font-weight: strong;
		}

		.ubf-form--login & {
			padding-top: 0;
		}

		.ubf-field--group > .ubf-field__content > & {
			margin-left: 0;
		}

		.ubf-field--password,
		.ubf-field--text & {
			display: block;
		}

		.ubf-field--file & {
			align-items: flex-start;
			display: flex;
			flex-direction: column;
			width: 100%;

			@include media($largeLayoutBreakpoint) {
				flex-direction: row;
			}
		}

		&--checkbox {
			cursor: pointer;

			input[type="checkbox"] + & {
				@include replaceInput($circle: false);
			}

			input[type="checkbox"]:checked + &::after {
				opacity: 1;
			}

			// input[type="checkbox"][disabled="disabled"] + &::before {
			// 	display: none;
			// }

			input[disabled] + &::before {
				cursor: default;
				background-color: $colorInputBorder;
			}

			input:focus + &::before {
				@include ubf-highlightColor(outline-color);
				outline-width: $inputBorderSize;
				outline-style: solid;
			}
		}

		&--file {
			margin-left: 0;
		}

		&--radio {
			input[type="radio"] + & {
				@include replaceInput($circle: true);
			}

			input[type="radio"]:checked + &::after {
				opacity: 1;
			}

			// input[type="radio"][disabled="disabled"] + &::before {
			// 	display: none;
			// }

			input[disabled] + &::before {
				background-color: $colorInputBorder;
			}

			input:focus + &::before {
				@include ubf-highlightColor(outline-color);
				outline-width: $inputBorderSize;
				outline-style: solid;
			}

			.ubf-field--radioText & {
				bottom: 0;
				flex-grow: 0;
				left: 0;// $base3;
				padding-right: 0;
				padding-left: 0;
				position: absolute;
				right: 0;
				top: 0;
			}

			.ubf-field__inputWrapper--extended & {
				@include media($largeLayoutBreakpoint) {
					flex-grow: 1;
				}
			}
		}

		&--radioText {
			padding: $labelNestedPadding 0;
			flex-grow: 1;
		}

		.ubf-field__inputWrapper--extended & {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(6, 12, $gridGutterSmall, -$base3);
			}
		}
	}


	&__labelValue {
		.ubf-field--required > .ubf-field__content > .ubf-field__label &::after,
		.ubf-field--required > .ubf-field__content > .ubf-field__inputWrapper > .ubf-field__label &::after {
			content: '*';
		}
	}


	&__selectIcon {
		color: $colorInput;
		position: absolute;
		right: $baseHalf;
		top: 50%;
		height: $base;
		width: $base;
		text-align: center;
		transform: translateY(-50%) rotate(90deg);
		z-index: 1;


		[data-type="svg"] {
			width: auto;
			height: 100%;
		}
	}

	&__text {
		margin-left: $base3;
		color: $colorSubNav;

		&--info {
			margin-top: $base3;
			margin-left: 0;
			margin-bottom: $base3;
		}
	}


	&__fileInfo {
		background-color: $colorBgBase;
		border: 1px solid $colorSubNav;
		display: block;
		min-height: $base4;
		flex-shrink: 0;
		margin-bottom: $base2;
		padding: 0.65rem;
		width: 100%;

		@include media($largeLayoutBreakpoint) {
			margin-bottom: 0;
			// width: staticColumnSize(4, 9, $gridGutterLarge);
			width: $base * 30;
		}
	}


	&__link {
		color: $colorSubNav;
		display: block;
		font-weight: bold;
		margin-left: $base3;
		padding-bottom: $base1;
		text-decoration: none;

		@include onHover {
			color: $colorBase;
		}
	}

	&--accordionController {
		width: 100%;
		position:absolute;
		margin-top: -4rem;
	}

}
