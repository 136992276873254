.ubf-gallery {
	position: relative;


	&__item {
		width: dynamicColumnSize(1, 1, $gridGutterSmall);

		@include media($largeLayoutBreakpoint) {
			width: dynamicColumnSize(1, 4, $gridGutterLarge);
		}

		.ubf-body--internal & {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(1, 3, $gridGutterLarge);
			}
		}
	}


	&__items {
		@include dynamicColumns($hSpace: $gridGutterSmall, $vSpace: $gridGutterLarge * 2, $hSpacePos: right);
		margin-bottom: $base2;

		@include media($largeLayoutBreakpoint) {
			@include dynamicColumns($hSpace: $gridGutterLarge, $vSpace: $gridGutterLarge * 2, $hSpacePos: right);
		}
	}


	&__slideshow {
		display: none;
	}
}
