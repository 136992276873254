.ubf-skip-links {
  display: flex;
  justify-content: center;

  &__link {
	background-color: $colorBgBase;
	@include ubf-font(base);

	top: 0;
	transition: top ease-out 0.4s;
	@include visuallyHidden(true);

	&:focus {
	  @include visuallyHidden(false);
	  padding: $baseHalf $base;
	  z-index: 100;
	  position: absolute;
	  top: 10px;
	}

	@include media('print') {
	  display: none;
	}
  }

}
