.ubf-test {

	&__title {
		@include ubf-font(h2);
		@include ubf-highlightColor(color);

		.ubf-sidebar & {
			@include ubf-font(h3);
		}
	}

	&__content {
		position: relative;
		@include staticColumns($gridGutterLarge);
	}

	&__image {
		position: relative;
			//Grid: Hier werden die Verhältnisse angezeigt (also 1/3 i.d.F.)
		width: staticColumnSize(1, 1, $gridGutterSmall);

		@include media($largeLayoutBreakpoint) {
			//Grid: Hier werden die Verhältnisse angezeigt (also 1/3 i.d.F.)
			width: staticColumnSize(1, 3, $gridGutterLarge);
		}
		img {
			width: 100%
		}
	}

	&__description {
		position: relative;
		width: staticColumnSize(1, 1, $gridGutterSmall);

		@include media($largeLayoutBreakpoint) {
			width: staticColumnSize(2, 3, $gridGutterSmall);
		}

		color: $colorBase;
		margin-bottom: $base;

		&--active {
			color: $colorError;
		}
	}
	&__icon {
		display: inline-block;
		width: 50px;
		height: 50px;
	}

}
