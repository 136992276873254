.ubf-modalToggler {
	@include ubf-outlineOnFocus('&', '', $focusOutlineColorInverted);
	@mixin line() {
		backface-visibility: hidden;
		background-color: currentColor;
		height: $menuTogglerLineSize;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}

	@include ubf-highlightColor(color);


	&__label {
		@include line();
		background-color: transparent;
		font-size: 0;
		text-indent: -10000px;
		transform: translateY(#{$menuTogglerLineSpace + $menuTogglerLineSize});


		&::before {
			@include line();
			content: '';
			transform: rotate(45deg);
		}


		&::after {
			@include line();
			content: '';
			transform: rotate(-45deg);
		}
	}
}
