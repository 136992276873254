.ubf-videoreel-item {

	&__link {
		.ubf-preview .ubf-preview__title & {
			text-decoration: none;
		}
	}


	&__picture {
		width: 100%;
		aspect-ratio: 9/16;
		margin-bottom: 0;
		img {
			border-radius: $base2;
		}
	}

	&__cover {
		cursor: pointer;
		@include ubf-outlineOnFocus();
		display: block;
		color: currentColor;
		aspect-ratio: 9/16;
		transition: transform 0.25s ease-in;
		transform: translateY(0);
		&:hover {
			@include media($ibridLayoutBreakpoint) {
				transition: transform 0.4s ease-out;
				transform: translateY(-15px);
			}
		}
		&.js-playing {
			display: none;
		}
	}
	&__videoIcon {
		@include ubf-highlightColor(background-color);
		opacity: 0.85;
		color: $colorInverted;
		padding: 8px;
		height: $base4;
		left: 50%;
		position: absolute;
		top: 50%;
		text-align: center;
		width: $base4;
		margin-left: -$base2;
		margin-top: -$base2;

		[data-type="svg"] {
			width: 100%;
			height: 100%;
		}
	}

	&__title {
		@include ubf-font(h3);

		.ubf-sidebar & {
			@include media($largeLayoutBreakpoint) {
				@include ubf-font(h);
			}
		}
	}


	&__visual {
		border-radius: $base2;
	}
	&__iframe {
		aspect-ratio: 9/16;
		width: 100%;
		border: 0 none;
		border-radius: $base2;
	}
}
