.ubf-events {
	.ubf-sidebar & {
		@include media($largeLayoutBreakpoint) {
			@include ubf-highlightColor(border-bottom-color);
			border-bottom-width: $boxBorderSize;
			border-bottom-style: solid;
			padding-bottom: $base2;
		}
	}


	&__date {
		@include ubf-font(h2);

		@include media($largeLayoutBreakpoint) {
			@include ubf-font(h1);
		}

		.ubf-sidebar & {
			@include media($largeLayoutBreakpoint) {
				@include ubf-font(h3);
			}
		}
	}


	&__dayOfWeek {
		display: block;
	}


	&__item {
		& + & {
			@include ubf-highlightColor(border-top-color);
			border-top-style: solid;
			border-top-width: $boxBorderSize;
			margin-top: $base2;
			padding-top: $base2;

			.ubf-sidebar & {
				@include media($largeLayoutBreakpoint) {
					border-top: 0;
					padding-top: 0;
				}
			}
		}
	}


	&__items,
	&__noItems {
		.ubf-events__date + & {
			margin-top: $base2;

			@include media($largeLayoutBreakpoint) {
				margin-top: $base4;
			}

			.ubf-sidebar & {
				@include media($largeLayoutBreakpoint) {
					margin-top: $base2;
				}
			}
		}
	}
}
