.ubf-studyform {
	background-color: rgba(240,240,240,0.75);
	position: relative;
	display: flex;

	&__body {
		flex-grow: 0;

		* + & {
			margin-top: $base2;
		}
	}
	&__form {
		width: 100%;
		padding: $base3;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	&__title {
		@include ubf-font(h3);
		padding-bottom: $base;
	}
	&__select {
		appearance: none;
		color: $colorBase;
		display: block;
		background-color: $colorBgBase;
		border: 1px solid $colorBase;
		padding: 1rem;
		margin: 0 0 $base 0;
		border-radius: 0;
		background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2028%2017%22%20text-rendering%3D%22geometricPrecision%22%20shape-rendering%3D%22geometricPrecision%22%20style%3D%22white-space%3A%20pre%3B%22%3E%3Cpath%20d%3D%22M0%2C0C0%2C0%2C28.162%2C0%2C28%2C0C27.838%2C0%2C14%2C17%2C14%2C17Z%22%20fill%3D%22%2312f3b6%22%20stroke%3D%22none%22%2F%3E%3C%2Fsvg%3E");
		background-repeat: no-repeat;
		background-position: right 0.7rem top 50%;
		background-size: $base2 auto;
		&:disabled {
			background-image: none;
		}
	}
	&__button {
		@include ubf-font(h4);
		cursor: pointer;
		display: flex;
		align-items: center;
		gap: $base2;
		padding: $base $base2;
		text-transform: uppercase;
		background-color: $colorHighlightBase;
		border: none;
		&:disabled {
			background-color: $colorBgBase;
			color: $colorUnselected;
			cursor: not-allowed;
			& svg {
				opacity: .2;
			}
		}
	}
}