.ubf-preview {
	&--fixedBorder {
		@include ubf-highlightColor(border-bottom-color);
		border-bottom-style: solid;
		border-bottom-width: $boxBorderSize;
		padding-bottom: $base2;
	}

	&__link {
		.ubf-preview .ubf-preview__title & {
			text-decoration: none;
		}
	}


	&__picture {
		width: 100%;

		.ubf-preview:not(.ubf-preview--noCrop) & {
			@include aspectRatio(1);
		}
	}

	&__summary {
		@include ubf-highlightColor(border-color);
		border-style: solid;
		border-width: $boxBorderSize 0 0 0;
		padding: $base2 0;

		.ubf-previews--slider & {
			border-bottom-width: 0;
		}


		&:last-child {
			border-bottom-width: $boxBorderSize;

			.ubf-previews--slider & {
				border-bottom-width: 0;
			}
		}

		.ubf-preview__visual + & {
			padding-top: $base2;
			border-top-width: 0;
		}

		.ubf-body:not(.ubf-body--internal) & {
			border-width: 0;
		}

		.ubf-sidebar .ubf-contentBlock--preview:not(:first-child) & {
			border-top: 0;

			&:first-child {
				padding-top: 0;
			}
		}

		.ubf-sidebar .ubf-contentBlock--preview:first-child &:only-child {
			border-top: 0;
			padding-top: 0;
		}


		.ubf-preview--fixedBorder &:last-child,
		.ubf-preview--fixedBorder .editlink + & {
			border-bottom: none;
		}
	}


	&__text {
		@include ubf-font(base);

		.ubf-preview__title + & {
			margin-top: $base * 0.5;

			@include media($largeLayoutBreakpoint) {
				margin-top: $base;
			}
		}
	}


	&__thumbLink {
		@include ubf-outlineOnFocus();
		display: block;
		color: currentColor;
	}


	&__title {
		@include ubf-font(h3);

		.ubf-sidebar & {
			@include media($largeLayoutBreakpoint) {
				@include ubf-font(h);
			}
		}
	}


	&__visual {
		.ubf-preview__summary + & {
			padding-bottom: $base2;

			.ubf-preview:not(.ubf-preview--fixedBorder) & {
				@include ubf-highlightColor(border-bottom-color);
				border-bottom-style: solid;
				border-bottom-width: $boxBorderSize;
			}

			.ubf-body:not(.ubf-body--internal) & {
				border-bottom-width: 0;
				padding-bottom: 0;
			}
		}
	}
}
