@use "sass:math";

.ubf-logos {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	margin-left: -$gridGutterSmall * 0.5;
	margin-right: -$gridGutterSmall * 0.5;

	@include media($largeLayoutBreakpoint) {
		margin-left: -$gridGutterLarge * 0.5;
		margin-right: -$gridGutterLarge * 0.5;
	}

	@function highlightColumnSize($size, $total, $hSpace) {
		// sligth variation of the staticColumnSize function that take
		// negative margin of the container into account to support grid with undefined item amount
		@return calc(
			#{math.div(100% * $size, $total)} + #{(math.div($size, $total) - 1) * $hSpace - math.div($hSpace, $total)}
		);
	}

	&__content {
		position: relative;

		&::before {
			border-width: $boxBorderSize;
			border-color: #f0f0f0;
			border-style: solid;
			content: "";
			position: absolute;
			height: 100%;
			left: 0;
			top: 0;
			right: 0;
			width: 100%;
			z-index: 1;
		}
	}

	&__item {
		@include minAspectRatio(1);
		margin-bottom: $gridGutterSmall;
		margin-left: $gridGutterSmall * 0.5;
		margin-right: $gridGutterSmall * 0.5;
		position: relative;
		width: highlightColumnSize(1, 2, $gridGutterSmall);

		@include media(">xxSmall") {
			width: highlightColumnSize(1, 3, $gridGutterSmall);
		}

		/* prevent preview bug (reboot.scss) */	
		& figure  {
			margin-bottom:0px!important;
		}


		@include media($largeLayoutBreakpoint) {
			margin-bottom: $gridGutterLarge;
			margin-left: $gridGutterLarge * 0.5;
			margin-right: $gridGutterLarge * 0.5;
			width: highlightColumnSize(1, 7, $gridGutterLarge);
		}
		/** following pages column sizing **/
		.ubf-body--internal & {
			@include media($largeLayoutBreakpoint) {
				margin-bottom: $gridGutterLarge;
				margin-left: $gridGutterLarge * 0.5;
				margin-right: $gridGutterLarge * 0.5;
				width: highlightColumnSize(1, 5, $gridGutterLarge);
			}
		}
		
		.ubf-contentBlock--reduced & {
			@include media('>xxSmall') {
				width: highlightColumnSize(1, 2, $gridGutterLarge);
			}

			@include media('>ibrid') {
				width: highlightColumnSize(1, 3, $gridGutterSmall);
			}

			@include media($largeLayoutBreakpoint) {
				width: highlightColumnSize(1, 3, $gridGutterLarge);
			}
		}

		.ubf-contentBlock--sidebar & {
			width: highlightColumnSize(1, 3, $gridGutterSmall);

			@include media($largeLayoutBreakpoint) {
				width: highlightColumnSize(1, 2, $gridGutterLarge);
			}
		}

		.ubf-contentBlock--sidebar + .ubf-contentBlock--accordion .ubf-contentBlock--logos & {
			@include media(">xxSmall") {
				width: highlightColumnSize(1, 3, $gridGutterSmall);
			}

			@include media($largeLayoutBreakpoint) {
				width: highlightColumnSize(1, 3, $gridGutterLarge);
			}
		}
	}

	&__link {
		width: 100%;
		padding-bottom: 100%;
		
	}

	&__picture {
		width: 100% !important;
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		display: flex;
		height: 100%;
		&--svg {
			display: flex;
			justify-content: center;
			align-items: center;

		}
	}

	&__content {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	& .ubf-picture__img {
		width: 90% !important;
		position: absolute;
	}
}
