.ubf-scrollToTop {
	@include ubf-container();
	background-color: $colorBgBase;
	bottom: 0;
	display: flex;
	justify-content: flex-end;
	padding-bottom: $base;
	padding-top: $base;
	position: sticky;
	z-index: 3;

	.ubf-html.ubf-js-edge &,
	.ubf-html.ubf-js-ie & {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		visibility: hidden;
	}

	&:not(.ubf-js-visible) {
		visibility: hidden;
	}

	@include media($scrollTopOutsideBreakpoint) {
		background-color: transparent;
		pointer-events: none;
	}
}
