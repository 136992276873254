.ubf-eventsDetails {
	display: flex;
	flex-direction: column;

	.ubf-sidebar & {
		@include ubf-highlightColor(border-bottom-color);
		border-bottom-style: solid;
		border-bottom-width: $boxBorderSize;
		padding-bottom: $base2;
	}

	&__dayOfWeek {
		@include media($largeLayoutBreakpoint) {
			display: block;
		}
	}


	&__excerpts {
		* + & {
			margin-top: $base2;
		}
	}


	&__title {
		@include ubf-font(h2);
		flex-grow: 1;
		position: relative;

		.ubf-body--home & {
			@include media($largeLayoutBreakpoint) {
				@include ubf-font(h1);
			}
		}

		.ubf-sidebar & {
			@include media($largeLayoutBreakpoint) {
				@include ubf-font(h3);
			}
		}
	}
}
