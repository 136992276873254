.ubf-icon {
	display: inline-block;
	height: auto;
	line-height: 0;
	vertical-align: middle;
	width: $contentIconSize;

	[data-type="svg"] {
		height: auto;
		width: 100%;
	}
}
